import React from 'react';
import { createRoot } from 'react-dom/client';

import InitDataLoader from '@/InitDataLoader.tsx';

const App = () => {
	return <InitDataLoader isIndexPage={true} children={<></>} />;
};

const root = document.getElementById('_root_');
if (root) {
	createRoot(root).render(
		<React.StrictMode>
			<App />
		</React.StrictMode>,
	);
}

export default App;
